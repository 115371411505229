<template>
    <HrdCloudMobileHeader
        :show-back="true"
        :go-back-func="closeModal"
        title="KB스타런 현장의견"
    >
      <template v-slot:right>
        <div class="util util-btn">
          <a href="javascript:" class="util-actions util-actions-btn text-primary" @click="insertQaAndFiles">저장</a>
        </div>
      </template>
    </HrdCloudMobileHeader>
    <main class="kb-main" id="kb-hrdcloud">
      <div class="main-content main-component">
        <article class="content-section">
          <div class="kb-form-fields">
            <div class="kb-form-field field-border-0">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">현장의견</span><span class="kb-form-byte-text">{{ cnSz }} / 500</span></label>
              </div>
              <div class="kb-form-row">
                <textarea v-model="param.inqCn" name="" class="kb-form-control" placeholder="내용을 입력해 주세요."></textarea>
              </div>
            </div>
          </div>
        </article>
      </div>
    </main>
</template>

<script>
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';
import {
  insertFileAtch,
  insertQa,
  removeUpload,
} from '@/assets/js/modules/hrd/hrd-common';
import {convertToStorageBytes, initParams, isSuccess, isUploadSuccess} from '@/assets/js/util';
import {useAlert} from '@/assets/js/modules/common/alert';
import {computed, reactive, ref} from 'vue';
// import InputUpload from '@/components/common/InputUpload';
import store from '@/store';
import {MUT_SHOW_HRD_BADGE} from '@/store/modules/hrdcloud/hrdcloud';
import {getHrdBadgeList} from '@/assets/js/modules/hrd/hrd-badge';
import router from "@/router";

export default {
  name: 'MobileOpinion',
  components: {HrdCloudMobileHeader},
  setup() {
    const {showMessage} = useAlert();
    const inqTyCdDcds = [{ cd: '2091018', cdNm: 'KB스타런 현장의견' }];

    const param = reactive({
      tblNm: '',
      tblSn: 0,
      comInqSn: 0,
      inqTitle: 'KB스타런 현장의견',
      inqTyCdDcd: '2091018',
      inqTyNm: 'KB스타런 현장의견',
      inqCn: '',
      readYn: '',
      ansYn: '',
      ansCn: ''
    });

    const file = ref({ files: [], removed: [], binaries: [] });

    const cnSz = computed(() => {
      return (param.inqCn && param.inqCn.length ? param.inqCn.length : 0 );
    });

    const closeModal = () => {
      router.go(-1);
    };

    const validate = () => {
      if (!param.inqTitle) {
        showMessage('제목을 입력해주세요.');
        return false;
      }
      if (!param.inqCn) {
        showMessage('내용을 입력해주세요.');
        return false;
      }
      if (param.inqCn.length > 500) {
        showMessage('내용을 500자 이하로 작성해주세요.');
        return false;
      }
      return true;
    };

    const init = () => {
      initParams(param);
      initParams(file.value);
      param.tblNm = null;
      param.tblSn = null;
      param.inqTyCdDcd = '2091018';
      param.inqTyNm = 'KB스타런 현장의견';
    };

    const insertQaAndFiles = async () => {
      if (validate()) {
        let qa = await insertQa(param);
        if (isSuccess(qa)) {
          if (file.value.files.length > 0) {
            let fileRes = await insertFileAtch({comInqSn: qa.comInqSn}, file.value.files);
            if (isUploadSuccess(fileRes)) {
              showMessage("KB스타런 현장의견이 접수되었습니다", () => {
                init();
                closeModal();

                if (qa.badges && qa.badges.length > 0) {
                  store.commit(`hrdcloud/${MUT_SHOW_HRD_BADGE}`,qa.badges);
                  getHrdBadgeList();
                }
              });
            } else {
              showMessage("등록에 실패했습니다. 잠시 후 다시 시도해주세요.");
            }
          } else {
            showMessage("KB스타런 현장의견이 접수되었습니다", () => {
              init();
              closeModal();

              if (qa.badges && qa.badges.length > 0) {
                store.commit(`hrdcloud/${MUT_SHOW_HRD_BADGE}`,qa.badges);
                getHrdBadgeList();
              }
            });
          }
        } else {
          showMessage("등록에 실패했습니다. 잠시 후 다시 시도해주세요.");
        }
      }
    };

    // watch(() => show.value,() => {
    //   if (show.value) {
    //     lightMode();
    //   } else {
    //     toggleConcentrationMode();
    //   }
    // });

    return {
      inqTyCdDcds, param, file, cnSz,
      closeModal, insertQaAndFiles, convertToStorageBytes,
      removeUpload
    }
  }
};
</script>